import moment from 'moment';

//#region DateTime
export const formatDateTime = (datetime) => {
  const formated = moment(String(datetime)).locale('en').format('YYYY/MM/DD - hh:mm A');
  if (formated && formated != 'Invalid date') return formated;
  else return '---';
};
export const formatDate = (datetime) => {
  const formated = moment(String(datetime)).locale('en').format('YYYY/MM/DD');
  if (formated && formated != 'Invalid date') return formated;
  else return '---';
};
export const formatTime = (datetime) => {
  const formated = moment(String(datetime)).locale('en').format('hh:mm A');
  if (formated && formated != 'Invalid date') return formated;
  else return '---';
};
//#endregion DateTime

//#region Convert Object to FormData
const keyify = (obj, prefix = '', postfix = '') =>
  Object.entries(obj).reduce((res, el) => {
    if (Array.isArray(el[1])) {
      return [...res, ...keyify(el[1], `${prefix}${el[0]}[`, ']')];
    } else if (el[1] instanceof File) {
      return [...res, { key: prefix + el[0] + postfix, value: el[1] }];
    } else if (typeof el[1] === 'object' && el[1] !== null) {
      return [...res, ...keyify(el[1], prefix + el[0] + postfix + '[', ']')];
    }
    return [...res, { key: prefix + el[0] + postfix, value: el[1] }];
  }, []);

export const toFormData = (object = {}) => {
  const allKeys = keyify(object);
  const formData = new FormData();

  for (const key in allKeys) {
    formData.append(allKeys[key].key, allKeys[key].value);
  }
  return formData;
};
//#endregion Convert Object to FormData

//#region numbers
export const numberToAr = (number) => {
  if (number.toString().indexOf('0') > -1) number = number.toString().replace(/0/g, '٠');
  if (number.toString().indexOf('1') > -1) number = number.toString().replace(/1/g, '١');
  if (number.toString().indexOf('2') > -1) number = number.toString().replace(/2/g, '٢');
  if (number.toString().indexOf('3') > -1) number = number.toString().replace(/3/g, '٣');
  if (number.toString().indexOf('4') > -1) number = number.toString().replace(/4/g, '٤');
  if (number.toString().indexOf('5') > -1) number = number.toString().replace(/5/g, '٥');
  if (number.toString().indexOf('6') > -1) number = number.toString().replace(/6/g, '٦');
  if (number.toString().indexOf('7') > -1) number = number.toString().replace(/7/g, '٧');
  if (number.toString().indexOf('8') > -1) number = number.toString().replace(/8/g, '٨');
  if (number.toString().indexOf('9') > -1) number = number.toString().replace(/9/g, '٩');
  return number;
};
export const numberToEn = (number) => {
  if (number.toString().indexOf('٠') > -1) number = number.toString().replace(/٠/g, '0');
  if (number.toString().indexOf('١') > -1) number = number.toString().replace(/١/g, '1');
  if (number.toString().indexOf('٢') > -1) number = number.toString().replace(/٢/g, '2');
  if (number.toString().indexOf('٣') > -1) number = number.toString().replace(/٣/g, '3');
  if (number.toString().indexOf('٤') > -1) number = number.toString().replace(/٤/g, '4');
  if (number.toString().indexOf('٥') > -1) number = number.toString().replace(/٥/g, '5');
  if (number.toString().indexOf('٦') > -1) number = number.toString().replace(/٦/g, '6');
  if (number.toString().indexOf('٧') > -1) number = number.toString().replace(/٧/g, '7');
  if (number.toString().indexOf('٨') > -1) number = number.toString().replace(/٨/g, '8');
  if (number.toString().indexOf('٩') > -1) number = number.toString().replace(/٩/g, '9');
  return number;
};
//#endregion numbers

export const dropdownMap = (array, value = 'key', lable = 'value') => {
  const itemValue = value || 'key';
  const itemLable = lable || 'value';

  return (
    array?.map((i) => {
      return { value: i[itemValue], label: i[itemLable] };
    }) || []
  );
};

export const amountWithCurrency = (amount = 0, currency = '') => {
  return `${amount} ${currency}`;
};

export const downloadFile = (fileURL = '') => {
  // Create an anchor tag to trigger the download
  const link = document.createElement('a');
  link.href = fileURL.replace('http://', 'https://');
  link.download = 'data.xlsx'; // Set the file name or let the server determine it
  document.body.appendChild(link);

  // Trigger the click event
  link.click();

  // Clean up
  document.body.removeChild(link);
};
export const downloadPdf = (fileURL = '') => {
  window.open(fileURL, '_blank');
};
