import i18n from '@/i18n';
import store from '@/state/store';
import authServices from './services/auth';

const Layout = () => import('@/layouts/auth.vue');
const Login = () => import('./views/Login.vue');
const Logout = () => import('./views/Logout.vue');

export default [
  {
    path: '',
    redirect: { name: 'Auth.Login' },
  },
  {
    path: '/auth',
    component: Layout,
    children: [
      {
        path: 'login',
        name: 'Auth.Login',
        component: Login,
        meta: { title: i18n.global.t('auth.login'), notAuth: true },
      },
      {
        path: 'logout',
        name: 'Auth.Logout',
        component: Logout,
        meta: {
          title: i18n.global.t('auth.logout'),
          authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            authServices.logout();
            localStorage.clear();
            sessionStorage.clear();
            store.dispatch('authUser/setUserToken', null);
            store.dispatch('authUser/setUserRole', null);
            next();
          },
        },
      },
    ],
  },
];
