import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Companies = () => import('./views/Index.vue');
const CompanyView = () => import('./views/CompanyView.vue');

export default [
  {
    path: '/companies',
    component: Layout,
    meta: { authRequired: true, header: true, permission: 'superadmin|marketing' },
    children: [
      {
        path: '',
        name: 'Companies',
        component: Companies,
        meta: { title: i18n.global.t('companies.companies') },
      },
      {
        path: 'view/:id?',
        name: 'Companies.View',
        component: CompanyView,
        meta: { title: i18n.global.t('companies.addresses') },
        props: true,
      },
    ],
  },
];
