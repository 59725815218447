<template>
  <div>
    <label :for="id" class="form-label">
      {{ label }}
      <span v-if="validate.includes('required') && !disabled" class="required-status">*</span>
    </label>

    <Field v-slot="{ field, meta }" :name="name || id" :modelValue="file" :rules="!disabled ? validate : ''">
      <input
        type="file"
        class="form-control"
        :class="{ 'is-invalid': (Array.isArray(errors) && errors.length) || meta?.errors?.length }"
        :id="id"
        :modelValue="field.value"
        @change="handleFileChange($event)"
        :placeholder="placeholder"
        :disabled="disabled"
      />

      <div
        v-if="(Array.isArray(errors) && errors.length) || errors.length || meta?.errors?.length"
        class="invalid-feedback"
      >
        {{ Array.isArray(errors) ? errors[0] : errors }}
        {{ Array.isArray(meta.errors) ? meta.errors[0] : '' }}
      </div>
    </Field>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['change']);

const file = ref('');

defineProps({
  id: { type: String, default: '' },
  name: { type: String, default: '' }, // for validation
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  validate: { type: [String, Object], default: '' },
  errors: { type: [String, Array], default: '' },

  // maxSize: { type: [Number, String], default: 5 },
  // accept: { type: String, default: "image/*" },
});

const handleFileChange = (e) => {
  if (e.target.files && e.target.files[0]) {
    emit('change', e.target.files[0]);
    file.value = e.target.files[0];
  } else {
    emit('change', '');
    file.value = '';
  }
};
</script>
