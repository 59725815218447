import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Stocks = () => import('./views/Index.vue');
const StockView = () => import('./views/StockView.vue');

export default [
  {
    path: '/stocks',
    component: Layout,
    meta: { authRequired: true, header: true, permission: 'superadmin|marketing' },
    children: [
      {
        path: '',
        name: 'Stocks',
        component: Stocks,
        meta: { title: i18n.global.t('stocks.stocks') },
      },
      {
        path: 'view/:id?',
        name: 'Stocks.View',
        component: StockView,
        meta: { title: i18n.global.t('stocks.view'), pageType: 'view' },
        props: true,
      },
      {
        path: 'update/:id?',
        name: 'Stocks.Update',
        component: StockView,
        meta: { title: i18n.global.t('stocks.update'), pageType: 'update' },
        props: true,
      },
    ],
  },
];
