import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Users = () => import('./views/Index.vue');
const UserView = () => import('./views/UserView.vue');

export default [
  {
    path: '/users',
    component: Layout,
    meta: { authRequired: true, header: true, permission: 'superadmin' },
    children: [
      {
        path: '',
        name: 'Users',
        component: Users,
        meta: { title: i18n.global.t('users.users') },
      },
      {
        path: 'view/:id?',
        name: 'Users.View',
        component: UserView,
        meta: { title: i18n.global.t('users.view'), pageType: 'view' },
        props: true,
      },
    ],
  },
];
