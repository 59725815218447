import api from '@/utils/axios';
import { dropdownMap } from '@/utils/helper';

export const listDataTabl = (url, params = {}) => {
  return api().get(url, { params });
};

export const findItem = async (url, params = {}) => {
  let data = {};
  await api()
    .get(url, { params })
    .then((response) => {
      data = response?.data?.data || {};
    })
    .catch((error) => {
      console.log(error);
    });
  return { data, loading: false };
};
export const findDropdown = async (url, value = '', lable = '', params = {}) => {
  let data = {};
  await api()
    .get(url, { params })
    .then((response) => {
      data = dropdownMap(response?.data?.data || [], value, lable);
    })
    .catch((error) => {
      console.log(error);
    });
  return { data, loading: false };
};

export const deleteItem = (url, id) => {
  return api().delete(`${url}/${id}`);
};
export const confirmItem = (url, id, additional_url = '', type = '') => {
  switch (type) {
    case 'patch':
      return api().patch(`${url}/${id}${additional_url || ''}`);
    case 'post':
      return api().post(`${url}/${id}${additional_url || ''}`);
    case 'delete':
      return api().delete(`${url}/${id}${additional_url || ''}`);
    case 'put':
    default:
      return api().put(`${url}/${id}${additional_url || ''}`);
  }
};

// export default {
//     commonUploadFiles(payload, config) {
//       return api().post("media-files/upload", payload, config);
//     },
//     removeImage(id, type) {
//       return api().get(`remove-image/${id}/${type}`);
//     },
//     removeRow(url, rowId, baseURL = "") {
//       const apiClient = axios.create({
//         baseURL: !baseURL ? API_BASE_URL : baseURL,
//         headers: API_HEADERS,
//       });
//       apiClient.interceptors.request.use((config) => {
//         config.headers.Authorization =
//           `Bearer ${localStorage.getItem("access_token")}` || "";
//         config.headers.lang =
//           JSON.parse(localStorage.getItem("language"))?.value || "ar";
//         return config;
//       });
//       apiClient.interceptors.response.use((response) => {
//         return response;
//       }, apiResponseCatch);

//       return apiClient.delete(`${url}/${rowId}`);
//     },
//     changeStatus(payload) {
//       return api().post("change-status", payload);
//     },

//     downloadFile(url = "") {
//       url = url || "";
//       return api().get(url);
//     },
//     uploadFile(formData, url = "") {
//       url = url || "upload";
//       return api().post(url, formData);
//     },
// };
