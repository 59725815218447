export const state = {
  userToken: null,
  userRole: null,
};

export const getters = {
  userToken(state) {
    return state.userToken;
  },
  userRole(state) {
    return state.userRole;
  },
  loggedIn(state) {
    return !!state.userToken;
  },
};

export const mutations = {
  SET_USER_TOKEN(state, newValue) {
    state.userToken = newValue;
  },
  SET_USER_ROLE(state, newValue) {
    state.userRole = newValue;
  },
};

export const actions = {
  setUserToken({ commit }, token = null) {
    commit('SET_USER_TOKEN', token);
  },
  setUserRole({ commit }, role = null) {
    commit('SET_USER_ROLE', role);
  },
};
