import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Orders = () => import('./views/Index.vue');
const OrderView = () => import('./views/OrderView.vue');

export default [
  {
    path: '/orders',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'Orders',
        component: Orders,
        meta: {
          title: i18n.global.t('orders.orders'),
          pageType: 'All',
          permission: 'superadmin|warehouse|finance|customerservice|deliverymanagement|customer',
        },
      },
      {
        path: 'out-for-delivery',
        name: 'OrdersOutForDelivery',
        component: Orders,
        meta: {
          title: i18n.global.t('orders.ordersOutForDelivery'),
          pageType: 'OutForDelivery',
          permission: 'carrier',
        },
      },
      {
        path: 'deliverd',
        name: 'OrdersDeliverd',
        component: Orders,
        meta: { title: i18n.global.t('orders.ordersDeliverd'), pageType: 'Deliverd', permission: 'carrier' },
      },
      {
        path: 'view/:orderId?',
        name: 'Orders.View',
        component: OrderView,
        meta: {
          title: i18n.global.t('orders.view'),
          pageType: 'view',
          permission: 'superadmin|warehouse|finance|customerservice|deliverymanagement|customer|carrier',
        },
        props: true,
      },
    ],
  },
];
