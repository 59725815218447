import i18n from '@/i18n';
import { defineRule, configure } from 'vee-validate';
import * as AllRules from '@vee-validate/rules';

defineRule('decimal', (value, { decimals = '*' } = {}) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }
  if (Number(decimals) === 0) {
    return /^-?\d*$/.test(value);
  }
  // const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
  // const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
  const regex = new RegExp('^[+-]?\\d+(\\.\\d+)?$');

  return regex.test(value);
});

defineRule('password', (value) => {
  // let errors = [];
  if (value.length < 8) {
    // errors.push('Must be at least 8 characters long');
    return i18n.global.t('validation.lessThan');
  }
  if (value.length > 12) {
    // errors.push('Must be contain a maximum of 12 characters');
    return i18n.global.t('validation.graterThan');
  }

  const capitalRegex = /[A-Z]/;
  if (!capitalRegex.test(value)) {
    // errors.push('Must has at least 1 capital character');
    return i18n.global.t('validation.atLeastCapitalCharacter');
  }
  const smallRegex = /[a-z]/;
  if (!smallRegex.test(value)) {
    // errors.push('Must has at least 1 small character');
    return i18n.global.t('validation.atLeastSmallCharacter');
  }

  const numberRegex = /[0-9]/;
  if (!numberRegex.test(value)) {
    // errors.push('Must has at least 1 number');
    return i18n.global.t('validation.atLeastNumber');
  }
  const symbolRegex = /[!@#$%^&*]/;
  if (!symbolRegex.test(value)) {
    // errors.push('Must has at least 1 symbol');
    return i18n.global.t('validation.atLeastSymbol');
  }

  // return errors.length == 0 ? true : false;
  return true;
  // const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,12}$/;

  // return regex.test(value);
});

defineRule('minArrayLength', (value, [minLength]) => {
  if (Array.isArray(value) && value.length >= minLength) {
    return true;
  }
  return i18n.global.t('validation.minArrayLength', { number: minLength });
});

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

configure({
  generateMessage: (context) => {
    const messages = {
      required: i18n.global.t('validation.required'),
      email: i18n.global.t('validation.email'),
      digits: i18n.global.t('validation.digits', { msg: context.rule.params[0] }),
      numeric: i18n.global.t('validation.numeric'),
      decimal: i18n.global.t('validation.decimal'),
    };

    return messages[context.rule.name] || 'غير صحيح';
  },
});
