<template>
  <div>
    <label v-if="label" :for="id" class="form-label">
      {{ label }}
      <span v-if="validate.includes('required') && !disabled" class="required-status">*</span>
    </label>

    <Field v-slot="{ field, meta }" :name="name || id" :modelValue="modelValue" :rules="!disabled ? validate : ''">
      <flat-pickr
        class="form-control flatpickr-input"
        :class="{ 'is-invalid': (Array.isArray(errors) && errors.length) || meta?.errors?.length }"
        :id="id"
        :modelValue="field.value"
        @change="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        :config="config"
        :disabled="disabled"
      >
      </flat-pickr>

      <div
        v-if="(Array.isArray(errors) && errors.length) || errors.length || meta?.errors?.length"
        class="invalid-feedback"
      >
        {{ Array.isArray(errors) ? errors[0] : errors }}
        {{ Array.isArray(meta.errors) ? meta.errors[0] : '' }}
      </div>
    </Field>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import flatPickr from 'vue-flatpickr-component';
// import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import 'flatpickr/dist/flatpickr.css';

const props = defineProps({
  modelValue: { type: String, default: '' },
  id: { type: String, default: '' },
  name: { type: String, default: '' }, // for validation
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  type: { type: String, default: '' },
  mode: { type: String, default: '' },
  minDate: { type: String, default: '' },
  maxDate: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  hijri: { type: Boolean, default: false },
  validate: { type: [String, Object], default: '' },
  errors: { type: [String, Array], default: '' },
});

let enableTime, noCalendar, dateFormat, altFormat, configMode;

// type: 'datetime', 'date', or 'time'
if (props.type == 'date') {
  enableTime = false;
  noCalendar = false;
  dateFormat = 'Y-m-d';
  altFormat = 'Y/m/d';
} else if (props.type == 'time') {
  enableTime = true;
  noCalendar = true;
  dateFormat = 'G:i K';
  altFormat = 'G:i K';
} else {
  enableTime = true;
  noCalendar = false;
  dateFormat = 'Y-m-d G:i K';
  altFormat = 'Y/m/d G:i K';
}

// mode: 'single', 'multiple', or 'range'
if (props.mode && (props.mode == 'multiple' || props.mode == 'range')) {
  configMode = props?.mode || 'single';
} else {
  configMode = 'single';
}

const config = {
  altInput: true,
  enableTime: enableTime,
  noCalendar: noCalendar,
  dateFormat: dateFormat,
  altFormat: altFormat,
  mode: configMode,
  minDate: props.minDate,
  maxDate: props.maxDate,
  locale: { rangeSeparator: ' - ' },
  // formatDate: date => Umalqura.convertHijriToGregorian(date).format('YYYY-MM-DD'),
  // parseDate: str => Umalqura.convertGregorianToHijri(str).format('iYYYY-iMM-iDD'),
  // locale: Arabic,
};
</script>
