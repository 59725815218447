import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from './state/store';

import globalComponents from './utils/globalComponents';
import veeValidate from './utils/veeValidate';

import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from 'click-outside-vue3';
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/material/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

createApp(App)
  .use(store)
  .use(router)
  .use(globalComponents)
  .use(veeValidate)
  .use(BootstrapVueNext)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(i18n)
  .use(vClickOutside)
  .mount('#app');
