import store from '@/state/store';

export const checkPermission = (keys) => {
  const userRole = store.getters['authUser/userRole'] || '';
  const keyArray = keys.split('|'); // Convert "keys" string to an array
  return keyArray.includes(userRole);
};

export default {
  checkPermission,
};
