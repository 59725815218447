// export const API_BASE_URL = 'https://jsonplaceholder.typicode.com/'
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL || 'https://jsonplaceholder.typicode.com/';

export const LANGUAGES = [
  {
    flag: require('@/assets/images/flags/us.svg'),
    language: 'en',
    title: 'English',
    dir: 'ltr',
  },
  {
    flag: require('@/assets/images/flags/eg.svg'),
    language: 'ar',
    title: 'العربية',
    dir: 'rtl',
  },
  {
    flag: require('@/assets/images/flags/fr.svg'),
    language: 'fr',
    title: 'Français',
    dir: 'ltr',
  },
];

const I18N_LOCALE = localStorage.getItem('I18N_LOCALE') || process.env.VUE_APP_I18N_LOCALE;
const langIndex = LANGUAGES.findIndex((item) => item.language == I18N_LOCALE);
export const LANGUAGE = LANGUAGES[langIndex > -1 ? langIndex : 1];
// export const LANGUAGE = LANGUAGES[langIndex > -1 ? 1 : 1];
export const FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar';

export const PROJECT_NAME = 'Ana Raya';
