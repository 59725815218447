import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const MarketplaceServicesLogs = () => import('./views/Index.vue');

export default [
  {
    path: '/marketplace-services-logs',
    component: Layout,
    meta: { authRequired: true, header: true, permission: 'superadmin|marketplaceadmin' },
    children: [
      {
        path: '',
        name: 'MarketplaceServicesLogs',
        component: MarketplaceServicesLogs,
        meta: { title: i18n.global.t('marketplaceLogs.services') },
      },
    ],
  },
];
