<template>
  <router-view></router-view>
</template>

<script setup>
import { onMounted } from 'vue';
import { LANGUAGE } from './config/constant';

onMounted(() => {
  document.documentElement.setAttribute('lang', LANGUAGE.language);
  document.documentElement.setAttribute('dir', LANGUAGE.dir);

  // LANGUAGES.forEach((item) => {
  //   if (item.language == LANGUAGE.value) {
  //     document.documentElement.setAttribute('lang', item.language);
  //     document.documentElement.setAttribute('dir', item.dir);
  //   }
  // });
});
</script>
