<template>
  <div>
    <label v-if="label" :for="id" class="form-label">
      {{ label }}
      <span v-if="validate.includes('required') && !disabled" class="required-status">*</span>
    </label>

    <Field
      v-slot="{ field, meta }"
      :name="name || id"
      :modelValue="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :rules="!disabled ? validate : ''"
    >
      <div :class="{ 'position-relative auth-pass-inputgroup': password }">
        <input
          :type="type ? (type == 'password' ? (passwordVisable ? 'text' : 'password') : type) : 'text'"
          class="form-control"
          :class="{ 'is-invalid': (Array.isArray(errors) && errors.length) || meta?.errors?.length }"
          :id="id"
          v-bind="field"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :autocomplete="autocomplete ? 'on' : 'off'"
        />
        <BButton
          v-if="password"
          variant="link"
          class="position-absolute end-0 top-0 text-decoration-none text-muted"
          type="button"
          :id="`password-addon-${id}`"
          @click.prevent="passwordVisable = !passwordVisable"
        >
          <i class="ri-eye-fill align-middle"></i>
        </BButton>

        <div
          v-if="(Array.isArray(errors) && errors.length) || errors.length || meta?.errors?.length"
          class="invalid-feedback"
        >
          {{ Array.isArray(errors) ? errors[0] : errors }}
          {{ Array.isArray(meta.errors) ? meta.errors[0] : '' }}
          <!-- <span v-for="error in meta.errors" :key="error">{{ error }}</span> -->
        </div>
      </div>
    </Field>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';

const props = defineProps({
  modelValue: { type: String, default: '' },
  id: { type: String, default: '' },
  name: { type: String, default: '' }, // for validation
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  type: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  autocomplete: { type: Boolean, default: false },
  validate: { type: [String, Object], default: '' },
  errors: { type: [String, Array], default: '' },
});

const password = computed(() => {
  return props.type == 'password' ? true : false;
});
const passwordVisable = ref(false);
</script>
