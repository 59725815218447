import api from '@/utils/axios';

export default {
  login(data) {
    return api().post('Dashboard/SignInForAdmin', data);
  },
  logout() {
    return true;
    // return api().post('logout');
  },
};
