import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const AboutUs = () => import('./views/Index.vue');

export default [
  {
    path: '/about-us',
    component: Layout,
    meta: { authRequired: true, header: true },
    children: [
      {
        path: '',
        name: 'AboutUs',
        component: AboutUs,
        meta: { title: i18n.global.t('settings.aboutUs'), permission: 'superadmin|marketing' },
      },
    ],
  },
];
