<template>
  <BCol cols="12 d-flex justify-content-end gap-3">
    <BButton v-if="submitLabel" variant="primary" type="submit" :disabled="loading">
      <span class="d-flex align-items-center gap-2">
        <span v-if="loading" class="spinner-border flex-shrink-0" role="status" style="width: 20px; height: 20px">
          <span class="visually-hidden">Loading...</span>
        </span>
        <span class="flex-grow-1"> {{ submitLabel }} </span>
      </span>
    </BButton>

    <template v-if="actions.length > 0">
      <template v-for="(action, index) in actions" :key="index">
        <BButton
          v-if="action.emit"
          @click="$emit(action.emit)"
          :disabled="loading || action.disabled"
          class="btn btn-success d-flex gap-2"
          :class="action.class"
        >
          <LoadingSpinner v-if="loading" />
          <i v-else-if="action.icon" :class="action.icon"></i>
          {{ action.text }}
        </BButton>
        <router-link
          v-if="action.ifNavigate"
          :to="action.route"
          class="btn btn-success d-flex gap-2"
          :class="`${loading || action.disabled ? 'disabled' : ''} ${action.class}`"
        >
          <LoadingSpinner v-if="loading" />
          <i v-else-if="action.icon" :class="action.icon"></i>
          {{ action.text }}
        </router-link>
      </template>
    </template>

    <router-link v-if="backStatus" class="btn btn-outline-dark" :to="backRoute">
      <span class="d-flex align-items-center gap-2">
        <span class="flex-grow-1"> {{ backLabel ? backLabel : t('main.cancele') }} </span>
      </span>
    </router-link>
  </BCol>
</template>

<script setup>
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps({
  loading: { type: Boolean, default: false },
  submitLabel: { type: String, default: '' },
  backStatus: { type: Boolean, default: true },
  backLabel: { type: String, default: '' },
  backRoute: { type: [String, Object], default: '' },
  actions: { type: [Array], default: () => [] },
});
</script>
