<template>
  <div v-if="actions.length" class="d-flex w-full gap-3">
    <template v-for="(i, key) in actions">
      <span v-if="i.showIf ? i.showIf(dataItem) : true" :key="key" @click="fireAction(i)" class="cursor-pointer">
        <i v-b-tooltip.top="`${i.text}`" :class="[i.icon, `text-${i.color}`]" />
      </span>
    </template>

    <div v-if="actions.every((i) => i.showIf && !i.showIf(dataItem))">---</div>
  </div>
  <div v-else>---</div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { customAlert } from '../../utils/sweetalert';
import Swal from 'sweetalert2';
import { deleteItem, confirmItem } from '@/services/main';

const router = useRouter();
const emit = defineEmits(['reload', 'customEvent']);

const props = defineProps({
  actions: { type: Array, default: () => [] },
  dataItem: { type: Object, default: () => {} },
});

const fireAction = (action) => {
  if (action.ifNavigate) {
    let params = {};
    let path = action.routePath || null;
    let mainParams = action.params || {};

    const actionParams = action.paramsKeys || null;
    params[actionParams || 'id'] = props.dataItem[actionParams || 'id'];

    console.log('params: ', params);

    router.push(
      path || {
        name: action.routeName,
        params: { ...mainParams, ...params },
      },
    );
  } else if (action.actionName) {
    let objData = {};
    if (action.actionParams === 'fullObj') {
      objData = props.dataItem;
    } else if (action.actionParams) {
      action.actionParams.forEach((params) => {
        if (props.dataItem[params]) {
          objData[params] = props.dataItem[params];
        }
      });
    }
    emit('customEvent', { emit: action.actionName, objData: objData });
  } else if (action.type == 'delete' || action.type == 'confirm') {
    Swal.fire({
      title: `<h3>${action.actionHeader ? action.actionHeader : 'حذف'}</h3>`,
      html: `<p>
        ${action.type == 'delete' ? 'هل تريد حذف ' + (action.titleHeader ? action.titleHeader : 'العنصر') : ''}
        ${action.type == 'confirm' && action.question ? action.question : ''}
        <span class="text-danger">(${
          action.textContent ? props.dataItem[action.textContent] : props.dataItem.name
        })</span>
        ${action.type == 'delete' ? ' ؟' : ''}
      </p>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'نعم',
      denyButtonText: 'لا',
      icon: 'question',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (action.type == 'delete') {
          await deleteItem(action.url, props.dataItem[action.key || 'id'])
            .then((response) => {
              customAlert(response?.data?.message || 'تم المسح!', 'success');
              emit('reload');
            })
            .catch((error) => {
              customAlert(error?.response?.data?.message ?? 'error', 'error');
            });
        } else {
          await confirmItem(action.url, props.dataItem[action.key || 'id'], action.additional_url)
            .then((response) => {
              customAlert(response?.data?.message || 'تم!', 'success');
              emit('reload');
            })
            .catch((error) => {
              customAlert(error?.response?.data?.message ?? 'error', 'error');
            });
        }
      }
    });
  }
};
</script>
