import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Surveys = () => import('./views/Index.vue');
const SurveyView = () => import('./views/SurveyView.vue');
const SurveyAnswers = () => import('./views/SurveyAnswers.vue');

export default [
  {
    path: '/surveys',
    component: Layout,
    meta: { authRequired: true, header: true, permission: 'superadmin|marketing' },
    children: [
      {
        path: '',
        name: 'Surveys',
        component: Surveys,
        meta: { title: i18n.global.t('surveys.surveys') },
      },
      {
        path: 'view/:surveyId?',
        name: 'Surveys.View',
        component: SurveyView,
        meta: { title: i18n.global.t('surveys.view'), pageType: 'view' },
        props: true,
      },
      {
        path: 'create',
        name: 'Surveys.Create',
        component: SurveyView,
        meta: { title: i18n.global.t('surveys.create'), pageType: 'create' },
      },
      {
        path: 'update/:surveyId?',
        name: 'Surveys.Update',
        component: SurveyView,
        meta: { title: i18n.global.t('surveys.update'), pageType: 'update' },
        props: true,
      },

      {
        path: 'answers/:surveyId?',
        name: 'Surveys.Answers',
        component: SurveyAnswers,
        meta: { title: i18n.global.t('surveys.answers') },
        props: true,
      },
    ],
  },
];
