<template>
  <div v-if="!url"><span>---</span></div>

  <!-- pdf -->
  <div v-else-if="url.slice(-4).toLowerCase() == '.pdf'" @click="openFile(url)" class="table-img-container">
    <img src="@/assets/images/icons/pdf-file.svg" alt="" class="table-img" />
  </div>

  <!-- word -->
  <div
    v-else-if="
      url.slice(-4).toLowerCase() == '.doc' ||
      url.slice(-5).toLowerCase() == '.docx' ||
      url.slice(-4).toLowerCase() == '.dot' ||
      url.slice(-5).toLowerCase() == '.dotx'
    "
    @click="openFile(url)"
    class="table-img-container"
  >
    <img src="@/assets/images/icons/word-file.svg" alt="" class="table-img" />
  </div>

  <!-- excel -->
  <div
    v-else-if="
      url.slice(-4).toLowerCase() == '.xla' ||
      url.slice(-5).toLowerCase() == '.xlam' ||
      url.slice(-4).toLowerCase() == '.xll' ||
      url.slice(-4).toLowerCase() == '.xlm' ||
      url.slice(-4).toLowerCase() == '.xls' ||
      url.slice(-5).toLowerCase() == '.xlsm' ||
      url.slice(-5).toLowerCase() == '.xlsx' ||
      url.slice(-4).toLowerCase() == '.xlt' ||
      url.slice(-5).toLowerCase() == '.xltm' ||
      url.slice(-5).toLowerCase() == '.xltx'
    "
    @click="openFile(url)"
    class="table-img-container"
  >
    <img src="@/assets/images/icons/excel-file.svg" alt="" class="table-img" />
  </div>

  <!-- video -->
  <div
    v-else-if="
      url.slice(-4).toLowerCase() == '.mp4' ||
      url.slice(-4).toLowerCase() == '.mov' ||
      url.slice(-4).toLowerCase() == '.wmv' ||
      url.slice(-4).toLowerCase() == '.flv' ||
      url.slice(-4).toLowerCase() == '.avi' ||
      url.slice(-4).toLowerCase() == '.mkv' ||
      url.slice(-5).toLowerCase() == '.webm'
    "
    @click="openFile(url, 'video')"
    class="table-img-container"
  >
    <img src="@/assets/images/icons/video.svg" alt="" class="table-img" />
  </div>

  <!-- image -->
  <div v-else @click="openFile(url, 'image')" class="table-img-container">
    <img :src="url" alt="" class="table-img" />
  </div>

  <BModal
    v-if="fileModal"
    size="xl"
    v-model="fileModal"
    hide-footer
    title=""
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
  >
    <div :key="fileUrl" class="media-container">
      <video v-if="type == 'video'" controls>
        <source :src="fileUrl" :type="fileType" />
        Your browser does not support the video tag.
      </video>
      <img v-else-if="type == 'image'" :src="fileUrl" alt=" " />
    </div>
  </BModal>
</template>

<script setup>
import { ref, defineProps } from 'vue';

defineProps({ url: { type: String, default: '' } });

const fileModal = ref(false);
const fileUrl = ref('');
const fileType = ref('');
const type = ref('');

const openFile = (url, type = '') => {
  if (type == 'video') {
    openModal({ type: 'video', fileUrl: url, fileType: 'video/mp4' });
  } else if (type == 'image') {
    openModal({ type: 'image', fileUrl: url, fileType: '' });
  } else {
    window.open(url, '_blank');
  }
};

const openModal = (e) => {
  type.value = e.type;
  fileUrl.value = e.fileUrl;
  fileType.value = e.fileType;
  fileModal.value = true;
};
</script>

<style lang="scss" scoped>
.table-img-container {
  height: 3rem;
  width: 3rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: var(--vz-light);
}
.table-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.media-container {
  width: 100%;
  direction: ltr;
  text-align: center;
  video {
    width: auto;
    height: auto;
    max-height: 80vh;
    max-width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
}
</style>
