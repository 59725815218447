<template>
  <BCard no-body>
    <BCardHeader
      v-if="title || searchStatus || createStatus || actions.length > 0 || sortedFilters.length > 0"
      class="border-0"
    >
      <BRow class="g-4">
        <BCol v-if="title" sm="auto">
          <h2 class="h4">{{ title }}</h2>
        </BCol>

        <BCol v-if="searchStatus" sm="auto">
          <div>
            <div class="search-box ms-2">
              <input type="text" class="form-control" v-model="textSearch" placeholder="بحث ..." />
              <i class="ri-search-line search-icon"></i>
            </div>
          </div>
        </BCol>
        <BCol v-for="(item, index) in sortedFilters" sm="auto" :key="index" style="min-width: 300px">
          <MainInput
            v-if="item.type == 'text'"
            :modelValue="params[item.key]"
            :id="`filter-${item.key}`"
            :placeholder="item.label"
            @update:modelValue="changeText(item.key, $event)"
          />
          <MainDateTimePickr
            v-else-if="item.type == 'date'"
            :modelValue="params[item.key]"
            :id="`filter-${item.key}`"
            :placeholder="item.label"
            type="date"
            @update:modelValue="$emit('changeFilter', { key: item.key, value: $event })"
          />
          <MainSelect
            v-else
            :modelValue="params[item.key]"
            :id="`filter-${item.key}`"
            :placeholder="t('main.selectItem', { msg: item.label })"
            :options="item.options"
            @update:modelValue="$emit('changeFilter', { key: item.key, value: $event })"
          />
        </BCol>

        <BCol v-if="createStatus || actions.length > 0" sm>
          <div class="d-flex justify-content-sm-end gap-2">
            <router-link
              v-if="createStatus && createRoute"
              :to="createRoute"
              :class="{ disabled: LoadingStatus || createDisabled }"
              class="btn btn-success d-flex gap-2"
            >
              <LoadingSpinner v-if="LoadingStatus" />
              <i v-else class="ri-add-line"></i>
              {{ createLabel || t('main.create') }}
            </router-link>
            <button
              v-else
              :class="{ disabled: LoadingStatus || createDisabled }"
              class="btn btn-success d-flex gap-2"
              @click="$emit('create')"
            >
              <LoadingSpinner v-if="LoadingStatus" />
              <i v-else class="ri-add-line"></i>
              {{ createLabel || t('main.create') }}
            </button>

            <template v-if="actions.length > 0">
              <template v-for="(action, index) in actions" :key="index">
                <BButton
                  v-if="action.emit"
                  @click="$emit(action.emit)"
                  :disabled="LoadingStatus"
                  class="btn btn-success d-flex gap-2"
                >
                  <LoadingSpinner v-if="LoadingStatus" />
                  <i v-else-if="action.icon" :class="action.icon"></i>
                  {{ action.text }}
                </BButton>
                <router-link
                  v-if="action.ifNavigate"
                  :to="action.route"
                  :class="{ disabled: LoadingStatus }"
                  class="btn btn-success d-flex gap-2"
                >
                  <LoadingSpinner v-if="LoadingStatus" />
                  <i v-else-if="action.icon" :class="action.icon"></i>
                  {{ action.text }}
                </router-link>
              </template>
            </template>
          </div>
        </BCol>
      </BRow>
    </BCardHeader>

    <BCardBody>
      <div class="table-responsive table-card mb-1">
        <table class="table table-nowrap align-middle">
          <thead class="text-muted table-light">
            <tr class="text-uppercase">
              <th v-for="field of fields" :key="field.key" data-sort="id">{{ field.label }}</th>
            </tr>
          </thead>
          <template v-if="!LoadingStatus">
            <tbody class="list form-check-all" v-for="(item, index) of listOfData" :key="index">
              <tr>
                <td v-for="field of fields" :key="field.key">
                  <div v-if="field.key == '#'">
                    {{ (current_page - 1) * per_page + index + 1 }}
                  </div>

                  <div v-else-if="field.type == 'file'">
                    <CellFile :url="get(item, field.key)" />
                  </div>

                  <div v-else-if="field.type == 'actions'">
                    <CellActions
                      :actions="field.actions"
                      :data-item="item"
                      @reload="reload"
                      @customEvent="$emit($event.emit, $event.objData)"
                    />
                  </div>

                  <div v-else-if="field.type == 'datetime'">
                    {{ formatDateTime(get(item, field.key)) }}
                  </div>
                  <div v-else-if="field.type == 'date'">
                    {{ formatDate(get(item, field.key)) }}
                  </div>
                  <div v-else-if="field.type == 'time'">
                    {{ formatTime(get(item, field.key)) }}
                  </div>

                  <div v-else-if="field.type == 'boolean'">
                    {{
                      get(item, field.key) === true
                        ? t('main.yes')
                        : get(item, field.key) === false
                        ? t('main.no')
                        : '---'
                    }}
                  </div>

                  <div v-else-if="field.type == 'translation'">
                    {{ get(item, field.key) != null ? $t(`${field.translationKey}${get(item, field.key)}`) : '---' }}
                  </div>
                  <div v-else-if="field.type == 'change_status'">
                    <MainCheckbox
                      :modelValue="get(item, field.key)"
                      @update:modelValue="$emit('changeStatus', { item: item, status: $event })"
                      :disabled="field.disabled === true ? true : false"
                    />
                  </div>
                  <div v-else-if="field.type == 'array'">
                    <span
                      v-for="(arr, arrIndex) in field.max
                        ? get(item, field.key).slice(0, field.max)
                        : get(item, field.key)"
                      :key="arrIndex"
                    >
                      <template v-if="field.sub_key">
                        {{ get(arr, field.sub_key) }}
                        {{
                          get(item, field.key).length == arrIndex + 1
                            ? ''
                            : field.max && get(item, field.key).slice(0, field.max).length == arrIndex + 1
                            ? ' - ...'
                            : ' - '
                        }}
                      </template>
                      <template v-else> {{ arr }} - </template>
                    </span>
                  </div>

                  <div v-else>
                    <span
                      v-if="
                        (get(item, field.key) || get(item, field.key) == 0) &&
                        (!Array.isArray(get(item, field.key)) ||
                          (Array.isArray(get(item, field.key)) && get(item, field.key).length != 0))
                      "
                      :class="field.clickable || field.openInModal ? 'cursor-pointer' : ''"
                      @click="
                        field.clickable
                          ? $emit(`click-${field.key}`, item)
                          : field.openInModal
                          ? openInModal(get(item, field.key))
                          : false
                      "
                    >
                      {{
                        typeof get(item, field.key) === 'string' && get(item, field.key).length > 30
                          ? get(item, field.key).substring(0, 30) + '...'
                          : get(item, field.key)
                      }}
                    </span>
                    <span v-else>---</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </table>

        <!-- Loading -->
        <LoadingData v-if="LoadingStatus" />
        <!-- no data -->
        <NoResult v-else-if="listOfData.length < 1" />
      </div>

      <Pagination
        v-if="paginationStatus && listOfData.length >= 1"
        :current_page="current_page"
        :last_page="last_page"
        :per_page="per_page"
        @changePage="changePage($event)"
      />
    </BCardBody>
  </BCard>

  <BModal
    v-if="modalStatus"
    size="xl"
    v-model="modalStatus"
    hide-footer
    title=""
    header-class="p-3 bg-light"
    class="v-modal-custom custom-modal"
    centered
  >
    <div class="">
      <p>{{ modalText }}</p>
    </div>
  </BModal>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from 'vue';
import { get } from 'lodash';
import { formatDateTime, formatDate, formatTime } from '../../utils/helper';
// import { customAlert } from '../../utils/sweetalert';
import CellActions from './CellActions.vue';
import CellFile from './CellFile.vue';
import Pagination from './Pagination.vue';
import { listDataTabl } from '@/services/main';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['reload', 'changeStatus', 'create', 'changeFilter']);
const props = defineProps({
  title: { type: String, default: '' },
  fields: { type: Array, required: true },
  items: { type: [Array, Object], default: () => [] },
  staticData: { type: Boolean, default: false },
  params: { type: [Array, Object], default: () => [] },
  filters: { type: Array, default: () => [] },
  list_url: { type: String, default: '' },
  paginationStatus: { type: Boolean, default: true },
  searchStatus: { type: Boolean, default: false },
  createStatus: { type: Boolean, default: true },
  createDisabled: { type: Boolean, default: false },
  createLabel: { type: String, default: '' },
  createRoute: { type: [String, Object], default: '' },
  actions: { type: [Array], default: () => [] },
  loading: { type: Boolean, default: false },
  reloadData: { type: Boolean, default: false },
});

const tableLoading = ref(true);
const listOfData = ref([]);

const awaitingSearch = ref(false);
const textSearch = ref('');
const textToSend = ref('');

const current_page = ref(1);
const last_page = ref(1);
const per_page = 10;

const modalStatus = ref(false);
const modalText = ref('');

const LoadingStatus = computed(() => {
  return props.loading == true || tableLoading.value == true ? true : false;
});
const sortedFilters = computed(() => {
  const filters = props.filters;
  return filters.sort((a, b) => a.order - b.order);
});

const getListData = async () => {
  listOfData.value = [];
  let filters = {};
  if (props.params) filters = props.params;

  tableLoading.value = true;
  if (!props.staticData) {
    await listDataTabl(
      // `${props.list_url}?page=${current_page.value}&per_page=${per_page}&search_value=${textSearch.value || ''}`,
      props.list_url,
      { PageNumber: current_page.value, ...filters },
    )
      .then((response) => {
        listOfData.value = props.paginationStatus ? response?.data?.data?.itemsList || [] : response?.data?.data || [];
        last_page.value = response?.data?.data?.totalPageNumbers ?? 1;
      })
      .catch((error) => {
        console.log('error: ', error);
        listOfData.value = [];
        // customAlert(error?.response?.data?.message || 'error', 'error');
      });
  } else {
    listOfData.value = props.items;
  }
  tableLoading.value = false;
};

const changePage = (page) => {
  if (current_page.value == page) return;
  current_page.value = page;
  getListData();
};
const changeText = (key, value) => {
  textToSend.value = value;
  if (!awaitingSearch.value) {
    setTimeout(() => {
      emit('changeFilter', { key: key, value: textToSend.value });
      awaitingSearch.value = false;
    }, 1000); // 1 sec delay
  }
  awaitingSearch.value = true;
};
const reload = () => {
  getListData();
  emit('reload');
};

const openInModal = (text) => {
  modalStatus.value = true;
  modalText.value = text;
};

watch(
  () => props.items,
  () => {
    getListData();
  },
  { deep: true },
);
watch(
  () => props.params,
  () => {
    current_page.value = 1;
    getListData();
  },
  { deep: true },
);
watch(textSearch, () => {
  if (!awaitingSearch.value) {
    setTimeout(() => {
      current_page.value = 1;
      getListData();
      awaitingSearch.value = false;
    }, 1000); // 1 sec delay
  }
  awaitingSearch.value = true;
});
watch(
  () => props.reloadData,
  () => {
    if (props.reloadData) {
      current_page.value = 1;
      getListData();
    }
  },
);

getListData();
</script>

<style lang="scss">
.custom-modal .modal-dialog {
  max-width: 75vw;
  min-width: 400px;
  width: fit-content;
}
</style>
