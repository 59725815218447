import Swal from 'sweetalert2';

export const customAlert = (message, type = 'success') => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: type,
    title: message,
  });
};

// const swalWithBootstrapButtons = Swal.mixin({
//   customClass: {
//     confirmButton: "btn primary",
//     cancelButton: "btn danger",
//   },
//   buttonsStyling: false,
// });

// export const deleteAlert = (deleteFuncation) => {
//   Swal.fire({
//     title: $nuxt.$t("deleteAlert.title"),
//     icon: "warning",
//     showCancelButton: true,
//     confirmButtonColor: "#3085d6",
//     cancelButtonColor: "#d33",
//     confirmButtonText: "Yes, delete it!",
//   }).then((result) => {
//     if (result.isConfirmed) {
//       deleteFuncation;
//       Swal.fire("Deleted!", "Your file has been deleted.", "success");
//     }
//   });
// };
// export const alertNoti = async (country) => {
//   return swalWithBootstrapButtons
//     .fire({
//       title: $nuxt.$t("ALERT_TITLE") + country,
//       text: $nuxt.$t("ALERT_TEXT"),
//       showCancelButton: true,
//       confirmButtonText: $nuxt.$t("YES"),
//       cancelButtonText: $nuxt.$t("NO"),
//     })
//     .then((result) => {
//       return result;
//     });
// };
// export const confirmAlert = async (textTitle) => {
//   return swalWithBootstrapButtons
//     .fire({
//       title: textTitle,
//       showCancelButton: true,
//       confirmButtonText: $nuxt.$t("YES"),
//       cancelButtonText: $nuxt.$t("NO"),
//     })
//     .then((result) => {
//       return result.isConfirmed;
//     });
// };
