import i18n from '@/i18n';
const Layout = () => import('@/layouts/main.vue');
const Links = () => import('./views/Index.vue');

export default [
  {
    path: '/links',
    component: Layout,
    meta: { authRequired: true, header: true, permission: 'superadmin|marketing' },
    children: [
      {
        path: '',
        name: 'Links',
        component: Links,
        meta: { title: i18n.global.t('settings.links') },
      },
    ],
  },
];
